import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/hello",
  },
  {
    path: "/hello",
    component: () => import("./components/pc/HelloWorld.vue"),
  },
  {
    path: "/hello_mobile",
    component: () => import("./components/mobile/HelloWorldMobile.vue"),
  },
];

const router = createRouter({ history: createWebHistory(), routes });
export default router;
