<template>
  <router-view></router-view>
</template>

<script setup>
import "@/assets/css/global.css";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
onBeforeMount(() => {
  if (_isMobile()) {
    router.push("/hello_mobile");
  } else {
    router.push("/hello");
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
body {
  width: 100vm;
  overflow: hidden;
  overflow-y: scroll;
}
.el-popover.popstyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.winbtn {
  border: 0px !important;
  width: 100%;
}
</style>
